/**
 * 文書カテゴリ 新規登録系画面定義ファイル
 * 概要
 * 画面単位でのフロント定義について記載する
 * 更新履歴
 * 2022/03/26 追加 尾坂
 */

/** 自動生成で作成したフィールドについて、自動生成だけでは賄えない設定（主にデータベース参照型）を記載する **/
const displayFormSetting = [{}];

/** 登録ガイドメッセージ */
const authorityValidMessage = 'この内容でよろしければ「登録する」を押してください';
const authorityInvalidMessage = '登録権限を持たないユーザです';

/** 登録・編集完了時の確認ダイアログ */
const registerFinishText = '登録が完了いたしました';
const registerFinishAction = [
  {
    id: 1,
    color: 'primary',
    type: 'routing',
    name: 'toEdit',
    value: '詳細画面へ',
    route: '/doc_category_edit/:id'
  }
];

export default {
  displayFormSetting,
  authorityValidMessage,
  authorityInvalidMessage,
  registerFinishText,
  registerFinishAction
};
